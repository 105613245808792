import React from "react";
import {
  Section,
  Title,
  Content,
  SectionContentImage,
  SectionContent,
} from "./SectionStyle";
import {ButtonCustom} from "./Button";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

const HomeSection = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        transition: {
          type: "spring",
          duration: 4,
          bounce: 0.3,
        },
      });
    } else {
      animation.start({
        opacity: 0,
      });
    }
  }, [inView, animation]);

  return (
    <Section ref={ref}>
      <SectionContent as={motion.div} animate={animation}>
        <Title>Full-Fledged Decoratives and Designs</Title>
        <Content>
          Swag decoratives have a wide range of Laminates and Veneers which can
          turn your interiors to a luxiourious and aesthetic space.
        </Content>
        <ButtonCustom
          customStyle={{
            margin: "20px 0px 0px 0px",
            text: "Get in touch",
            linkTo: "/contact-us",
          }}
        />
      </SectionContent>
      <SectionContentImage as={motion.div} animate={animation}>
        <img
          src={require("../images/home-full-fledged-section.webp")}
          alt="Home"
          width={"100%"}
        />
      </SectionContentImage>
    </Section>
  );
};

export default HomeSection;
