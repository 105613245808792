import React from "react";
import styled from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  margin-top: 30px;

  img {
    transition: transform 0.2s;
  }

  img:hover {
    transform: scale(1.05);
  }
`;

const BottomText = styled.div`
  position: absolute;
  bottom: 30px;
  left: -30px;
  background-color: #161616;
  color: white;
  font-size: 1rem;
  font-family: "Belleza", sans-serif;
  font-weight: 800;
  padding: 30px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const ImageWithCaption = (props) => {
  return (
    <ImageContainer>
      <img src={props.content.url} alt="" width={"100%"}/>
      <BottomText>{props.content.title}</BottomText>
    </ImageContainer>
  );
};

export default ImageWithCaption;
