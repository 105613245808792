import styled from "styled-components";

export const Section = styled.div`
  /* background: #f6f7f8; */
  padding: 5% calc((100vw - 1100px) / 2);
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 10% 5%;
  }
`;


export const BottomSectionStyle = styled.div`
  padding: 5% calc((100vw - 1100px) / 2);
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 10% 5%;
  }
`;

export const Title = styled.h1`
  color: black;
  margin-top: 0px;
  font-family: "Belleza", sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
`;

export const Content = styled.p`
  color: #a0a0a6;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 24px;
`;


export const SectionContent = styled.div`
  flex: 1;
  margin-right: 5%;

  @media screen and (max-width: 768px) {
    margin: 10% 0 0 0;
  }
`;

export const SectionContentImage = styled.div`
  flex: 1;
  /* border-radius: 8px; */
  overflow: hidden;

  img {
    transition: transform 0.2s;
  }

  img:hover {
    transform: scale(1.2);
  }
`;