import { Link } from "react-router-dom";
import styled from "styled-components";

export const ProductSection = styled.div`
  padding: 5% calc((100vw - 1100px) / 2);
  display: flex;
  flex-direction: column;
`;

export const HeroSection = styled.div`
  display: flex;
  padding: 10% 0;
  height: 100%;
  color: white;
  background-color: black;
  flex-direction: column;
`;

export const HeroContentSection = styled.div`
  margin: 0 calc((100vw - 1100px) / 2);

  @media screen and (max-width: 768px) {
    margin: 0 5%;
  }
`;

export const HeroContainerTitleSide = styled.span`
  font-family: "a Auto Signature", sans-serif;
  font-size: 4rem;
  color: white;
  right: -30px;
  backface-visibility: hidden;
  transform: translateX(13.5px);
  opacity: 0.5;
  position: absolute;
  display: block;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const HeroParagraph = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin: 2% 0 0 0;
  color: white;
  line-height: 32px;
`;

export const HeroButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TitleSignature = styled.div`
  font-family: "a Auto Signature", sans-serif;
  font-size: 1.8rem;
  color: #b72e2c;
  margin: 0;
  font-weight: 800;
`;

export const Title = styled.div`
  font-family: "Belleza", sans-serif;
  font-size: 3.5rem;
  font-weight: 500;
  margin: 0;
`;

export const MainProductSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5% calc((100vw - 1100px) / 2);

  @media screen and (max-width: 768px) {
    padding: 10% 5%;
  }
`;

export const SpacesSection = styled.div``;

export const MainProductSectionImage = styled.div`
  flex: 0.85;
  position: relative;
  overflow: hidden;

  img {
    transition: transform 0.2s;
  }

  img:hover {
    transform: scale(1.1);
  }
`;

export const MainProductSectionContent = styled.div`
  flex: 1;
`;

export const MainParagraph = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin: 1% 0 0 0;
  color: black;
  line-height: 32px;
  text-align: justify;

  @media screen and (max-width: 768px) {
    margin: 0 0 10% 0;
    text-align: left;
  }
`;

export const MainContentSubSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 2% 0 0 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 5% 0 0 0;
  }
`;

export const MainContentSubSectionPara = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: black;
  padding: 0 0 0 3%;
  flex: 0.15;
  line-height: 24px;
  text-align: justify;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const MainGallerySection = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

export const MainGallerySingleImageContainer = styled.div`
  height: auto;
  padding: 2px;
  margin: 4px 0px;
  border-width: 2px;
  border-style: ${(props) => (props.selected ? "solid" : "none")};
  border-color: #b72e2c;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  img {
    transition: transform 0.2s;
  }

  img:hover {
    transform: scale(1.1);
  }
`;

export const MainGallerySingleImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ImageCaption = styled.p`
  position: absolute;
  bottom: 0px;
  left: 20px;
  font-style: italic;
  color: white;
  font-size: 1.1rem;
  font-family: "Belleza", sans-serif;
  font-weight: 600;
`;

export const SpaceSelectionButton = styled.span`
  position: relative;
  text-decoration: none;
  display: inline-block;
  color: black;
  font-weight: 800;
  padding: 0 5px;
  transition: color ease 0.3s;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 5%;
    left: 0;
    bottom: 0;
    background-color: #b72e2c;
    transition: all ease 0.3s;
  }

  &:hover {
    color: white;
    cursor: pointer;
  }

  &:hover::after {
    height: 100%;
  }
`;


export const RowSection = styled.div`
  padding: 5% calc((100vw - 1100px) / 2);
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    margin: 0 5%;
    flex-direction: column;
  }
`;

export const RowSectionFlipped = styled.div`
  padding: 5% calc((100vw - 1100px) / 2);
  display: flex;
  flex-direction: row-reverse;

  @media screen and (max-width: 768px) {
    margin: 0 5%;
    flex-direction: column;
  }
`;

export const SectionContent = styled.div`
  flex: 1;
  padding: 0 5%;

  @media screen and (max-width: 768px) {
    padding: 5% 0;
  }
`;

export const SectionImage = styled.div`
  flex: 1;
  overflow: hidden;

  img {
    transition: transform 0.2s;
  }

  img:hover {
    transform: scale(1.2);
  }
`;

export const Content = styled.p`
  color: black;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  line-height: 32px;
`;

// export const SubTitle = styled.div`
//   font-family: "Poppins", sans-serif;
//   color: black;
//   font-size: 1.4rem;
//   margin: 0 0 2% 0;
// `;

// export const SingleProductItem = styled.div`
//   background: #f6f7f8;
//   padding: 3%;
//   display: flex;
//   margin-top: 3%;
//   border-radius: 18px;
//   flex-direction: row;
// `;

// export const SectionContent = styled.div`
//   flex: 2;
// `;

// export const SectionImage = styled.div`
//   flex: 1;
//   overflow: hidden;
//   border-radius: 18px;

//   img {
//     transition: transform 0.2s;
//   }

//   img:hover {
//     transform: scale(1.1);
//   }
// `;

// export const SectionTitle = styled.h1`
//   color: black;
//   font-family: "Poppins", sans-serif;
//   font-weight: 800;
//   font-size: 1.5rem;
//   margin: 0 0 0 0;
// `;

// export const SectionContentText = styled.p`
//   color: #a0a0a6;
//   font-family: "Poppins", sans-serif;
//   font-size: 1rem;
//   margin: 10px 0 0 0;
// `;
