import { TextField } from "@mui/material";
import { BsTelephone } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { ButtonCustom, FormButtonCustom } from "../components/Button";
import GoToTop from "../ScrollToTop";
import {
  ContactInfo,
  ContactInfoBlock,
  ContactInfoBlockContent,
  FormField,
  FormFieldInputField,
  FormSection,
  Section,
  SubTitle,
  Title,
} from "./Contact.style";
import { motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Contact() {
  let query = useQuery();
  console.log(query.get("result"));

  let isCaptchaVerified = false;

  function onChange(value) {
    console.log("Captcha value:", value);
    isCaptchaVerified = true
  }

  // const handleFormSubmit = (event) => {
  //   event.preventDefault();
  //   console.log("su");
  //   event.onSubmit();
  // };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Section>
        <Title>Contact Us</Title>
        <SubTitle> Reach us at</SubTitle>
        <ContactInfo>
          <ContactInfoBlock>
            <GrLocation />
            <ContactInfoBlockContent>
              KH.NO. 62/11 & 61/15 South
              <br />
              Mundka Udyog Nagar
              <br />
              Delhi, 110041
            </ContactInfoBlockContent>
          </ContactInfoBlock>
          <ContactInfoBlock>
            <BsTelephone />
            <ContactInfoBlockContent href="tel:+917290911611">
              +91-72909-11611
            </ContactInfoBlockContent>
          </ContactInfoBlock>
        </ContactInfo>
        <SubTitle>Let's talk</SubTitle>
        {query.get("result") === "success" ? (
          <FormSection>
            <img
              src={require("./icon/checked.png")}
              width="100px"
              height={"auto"}
              alt="icon"
            />
            <SubTitle style={{ fontSize: "1.5rem", marginTop: "5%" }}>
              Thank you for your query! We will get back to you soon!
            </SubTitle>
          </FormSection>
        ) : (
          <form action="/handler.php" method="POST">
            <FormSection>
              <FormField>
                <FormFieldInputField>
                  <TextField
                    fullWidth
                    required
                    name="name"
                    id="standard-basic"
                    label="Your name"
                    variant="standard"
                  />
                </FormFieldInputField>
                <FormFieldInputField>
                  <TextField
                    fullWidth
                    required
                    name="email"
                    id="standard-basic"
                    label="Email address"
                    variant="standard"
                  />
                </FormFieldInputField>
              </FormField>
              <FormField>
                <FormFieldInputField>
                  <TextField
                    fullWidth
                    required
                    name="phone"
                    id="standard-basic"
                    label="Phone number"
                    variant="standard"
                  />
                </FormFieldInputField>
                <FormFieldInputField>
                  <TextField
                    fullWidth
                    name="company"
                    id="standard-basic"
                    label="Company"
                    variant="standard"
                  />
                </FormFieldInputField>
              </FormField>
              <FormFieldInputField>
                <TextField
                  fullWidth
                  multiline
                  minRows={4}
                  name="message"
                  id="standard-basic"
                  label="Message"
                  variant="standard"
                />
              </FormFieldInputField>
              <FormFieldInputField>
                <ReCAPTCHA
                  data-callback="capcha_filled"
                  data-expired-callback="capcha_expired"
                  sitekey="6Ld4SwAgAAAAAOBb4Ly2YkzoL9c8K5nGGabd7kOf"
                  onChange={onChange}
                />
              </FormFieldInputField>
              <FormButtonCustom
                type="submit"
                customStyle={{
                  margin: "20px 0px 0px 0px",
                  text: "Submit",
                }}
              />
            </FormSection>
          </form>
        )}
        <GoToTop />
      </Section>
    </motion.div>
  );
}

export default Contact;
