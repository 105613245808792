import styled from "styled-components";

export const TitleSignature = styled.div`
  font-family: "a Auto Signature", sans-serif;
  font-size: 1.8rem;
  color: #b72e2c;
  margin: 0;
  font-weight: 800;
`;

export const Section = styled.div`
  padding: 5% calc((100vw - 1100px) / 2);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 10% 5%;
  }
`;

export const SubTitle = styled.div`
  font-family: "Belleza", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const Content = styled.p`
  color: black;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  line-height: 32px;
`;

export const TextCenter = styled.div`
  text-align: center;
  margin-top: 5%;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 10%;
`;

export const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 0.6;
`;

export const ItemTitle = styled.div`
  font-family: "a Auto Signature", sans-serif;
  font-size: 1.2rem;
  color: #b72e2c;
  margin: 0;
  font-weight: 800;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const SectionImage = styled.div`
  position: relative;
  display: flex;
  flex: 0.4;
  margin-right: 3%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
`;

export const ItemImage = styled.img`
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

export const SectionImageGrid = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  margin-right: 3%;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
`;

export const ImageGrid = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3%;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin: 15% 0 0 0;
  }
`;
