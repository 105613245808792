import React from "react";
import ReactDOM from "react-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styled from "styled-components";
// ....

const MainContainer = styled.div`
  padding: 5% 0;

  @media screen and (max-width: 768px) {
    padding: 0 0 0 5%;
    margin-bottom: 10%;
  }
`;

const Header = styled.div`
  margin: 0% calc((100vw - 1100px) / 2);

  @media screen and (max-width: 768px) {
   margin: 10% 0 0 0;
  }
`;

const SectionTitle = styled.div`
  color: #c8252f;
  /* color: black; */
  line-height: 1em;
  font-size: 1.8rem;
  font-weight: 800;
  font-family: "a Auto Signature", sans-serif;
`;

const HeaderSubHead = styled.p`
  font-family: "Belleza", sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  margin: 5px 0 0 0;
`;

const CarouselItem = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 65vh;

  @media screen and (max-width: 768px) {
    height: 25vh;
  }
`;

const CarouselContainer = styled.div`
  margin: 3% 0 0 calc((100vw - 1100px) / 2);

  @media screen and (max-width: 768px) {
    margin: 10% 0 0 0;
  }
`;

const SliderData = [
  {
    image: "./images/carousel/1.jpeg",
  },
  {
    image: "./images/carousel/2.webp",
  },
  {
    image: "./images/carousel/3.webp",
  },
  {
    image: "./images/carousel/4.jpeg",
  },
];

const Carousel = () => {
  return (
    <MainContainer>
      <Header>
        <SectionTitle>Design that Speaks</SectionTitle>
        <HeaderSubHead>The epitome of finest quality</HeaderSubHead>
      </Header>
      <CarouselContainer>
        <OwlCarousel
          className="owl-theme"
          loop
          margin={20}
          nav={false}
          dots={false}
          autoplay={true}
        >
          {SliderData.map((slide, index) => {
            return (
              <div className="item" key={index}>
                <CarouselItem
                  style={{
                    backgroundImage: `url(${slide.image})`,
                  }}
                />
              </div>
            );
          })}
        </OwlCarousel>
      </CarouselContainer>
    </MainContainer>
  );
};

export default Carousel;
