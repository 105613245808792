import styled from "styled-components";

export const Section = styled.div`
  padding: 5% calc((100vw - 1100px) / 2);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 10% 5%;
  }
`;

export const Title = styled.h1`
  font-family: "a Auto Signature", sans-serif;
  font-size: 1.8rem;
  color: #b72e2c;
  margin: 0;
  font-weight: 800;
`;

export const SubTitle = styled.h2`
  font-family: "Belleza", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  color: black;
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const FormSection = styled.div`
  background-color: #f5f5f5;
  margin-top: 2%;
  padding: 5%;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin-top: 5%;
  }
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormFieldInputField = styled.div`
  flex: 1;
  margin: 25px;
`;

export const ContactInfo = styled.div`
  margin: 0 0 5% 0;

  @media screen and (max-width: 768px) {
    margin-bottom: 10%;
  }
`;

export const ContactInfoBlock = styled.div`
  margin: 15px 0 0 0;
  display: flex;
  flex-direction: row;
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  align-items: center;
`;

export const ContactInfoBlockContent = styled.a`
  margin: 0 0 0 15px;
  color: black;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  line-height: 32px;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 20px;
  }
`;
