import React, { useEffect, useState } from "react";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import {ButtonCustom} from "../Button";
import "./Slider.css";

const SliderData = [
  {
    image: require("../../images/slider/living-room-render.jpg"),
    head: ["GRACEFULLY CHIC &", <br />, "SWAGGER INTERIORS"],
  },
  {
    image: require("../../images/slider/bedroom-render.jpg"),
    head: ["FINEST DEPICTIONS &", <br />, "AESTHETIC DESIGNS"],
  },
  {
    image: require("../../images/slider/kitchen-render.jpg"),
    head: ["ADMIRABLE QUALITY &", <br />, "CUSTOMER SATISFACTION"],
  },
  {
    image: require("../../images/slider/office-render.jpg"),
    head: ["FINEST DEPICTIONS &", <br />, "AESTHETIC DESIGNS"],
  },
];

function Slider() {
  const [current, setCurrent] = useState(0);
  const length = SliderData.length;
  let slideInterval;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const startAutoPlay = () => {
    slideInterval = setInterval(nextSlide, 6000);
  };

  useEffect(() => {
    startAutoPlay();
    return () => clearInterval(slideInterval);
  }, [current]);

  if (!Array.isArray(SliderData) || SliderData.length <= 0) {
    return null;
  }

  return (
    <section className="slider">
      {SliderData.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
            style={{
              backgroundImage: `url(${slide.image}), linear-gradient(rgba(0,0,0,0.15),rgba(0,0,0,0.15))`,
            }}
          >
            <div className="content_section" key={index}>
              <div className="heading">
                <strong>{slide.head}</strong>
              </div>
              <ButtonCustom
                customStyle={{
                  margin: "30px 0px 0px 0px",
                  text: "Discover",
                  linkTo: "/products",
                }}
              />
            </div>
          </div>
        );
      })}
      {/* {SliderData.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
            style={{ backgroundImage: `url(${slide.image})`}}
          >
            {index === current && (
              <img src={slide.image} alt="travel" className="image" />
            )}
          </div>
        );
      })} */}
    </section>
  );
}

export default Slider;
