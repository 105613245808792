import React, { useState } from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  MobileNavMenu,
  MobileNavLink,
  BarsClose,
} from "./NavbarElements";

const Navbar = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleNavClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <Nav>
        <NavLink to="/">
          <img
            src={require("../../swag-logo.png")}
            alt="logo"
            height={"49px"}
          />
        </NavLink>
        {!isVisible ? (
          <Bars onClick={handleNavClick} />
        ) : (
          <BarsClose onClick={handleNavClick} />
        )}
        <NavMenu>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About us</NavLink>
          <NavLink to="/products">Products</NavLink>
          <NavLink to="/catalogues">Catalogues</NavLink>
          <NavLink to="/contact-us">Contact us</NavLink>
        </NavMenu>
      </Nav>
      {isVisible ? (
        <MobileNavMenu>
          <MobileNavLink to="/" onClick={handleNavClick}>
            Home
          </MobileNavLink>
          <MobileNavLink to="/about" onClick={handleNavClick}>
            About
          </MobileNavLink>
          <MobileNavLink to="/products" onClick={handleNavClick}>
            Products
          </MobileNavLink>
          <MobileNavLink to="/catalogues" onClick={handleNavClick}>
            Catalogues
          </MobileNavLink>
          <MobileNavLink to="/contact-us" onClick={handleNavClick}>
            Contact us
          </MobileNavLink>
        </MobileNavMenu>
      ) : (
        ""
      )}
    </>
  );
};

export default Navbar;
