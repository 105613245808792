import { FaBars, FaCross, FaWindowClose } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background: #ffffffe8;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1100px) / 2);
  z-index: 10;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  /* Third Nav */
  /* justify-content: flex-start; */
  /* -webkit-box-shadow: 0px 0px 10px 1px rgba(41, 41, 41, 0.1);
  -moz-box-shadow: 0px 0px 10px 1px rgba(41, 41, 41, 0.1);
  box-shadow: 0px 0px 10px 1px rgb(41 41 41 / 10%); */
`;

export const NavLink = styled(Link)`
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-family: "Roboto", sans-serif !important;
  cursor: pointer;
  &.active {
    color: #ca2e2d;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: black;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const BarsClose = styled(FaWindowClose)`
  display: none;
  color: black;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileNavMenu = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
  }
`;

export const MobileNavLink = styled(Link)`
  color: black;
  display: block;
  align-items: center;
  text-decoration: none;
  padding: 3% 0%;
  height: 100%;
  font-family: "Roboto", sans-serif !important;
  cursor: pointer;

  &.active {
    color: #ca2e2d;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #ca2e2d;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #ca2e2d;
  }
`;
