import styled from "styled-components";

export const Section = styled.div`
  padding: 5% calc((100vw - 1100px) / 2);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 10% 5%;
  }
`;

export const TitleSignature = styled.div`
  font-family: "a Auto Signature", sans-serif;
  font-size: 1.8rem;
  color: #b72e2c;
  margin: 0;
  font-weight: 800;
`;

export const SubTitle = styled.div`
  font-family: "Belleza", sans-serif;
  font-size: 4rem;
  font-weight: 500;
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 768px) {
    height: 50vh;
  }
`;

export const RowSection = styled.div`
  display: flex;
  margin-top: 3%;
  margin-bottom: 3%;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;


export const RowSectionFlipped = styled.div`
  display: flex;
  margin-top: 3%;
  margin-bottom: 3%;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const SectionContent = styled.div`
  flex: 1;
  padding: 0 3%;

  @media screen and (max-width: 768px) {
    margin-top: 5%;
    padding: 0;
  }
`;

export const SectionImage = styled.div`
  flex: 1;
  /* border-radius: 8px; */
  overflow: hidden;

  img {
    transition: transform 0.2s;
  }

  img:hover {
    transform: scale(1.2);
  }
`;

export const Content = styled.p`
  color: black;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  line-height: 32px;
`;

export const OurValues = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2%;
  /* height: 100%; */
  overflow-x: hidden;
  justify-content: center;
  /* flex-wrap: nowrap; */

  @media screen and (max-width: 768px) {
    flex: 1;
    flex-direction: column;
  }
`;