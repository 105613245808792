import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ButtonCustomProduct } from "../components/Button";
import GoToTop from "../ScrollToTop";
import {
  Content,
  HeroButtons,
  HeroContainerTitleSide,
  HeroContentSection,
  HeroParagraph,
  HeroSection,
  ImageCaption,
  MainContentSubSection,
  MainContentSubSectionPara,
  MainGallerySection,
  MainGallerySingleImage,
  MainGallerySingleImageContainer,
  MainParagraph,
  MainProductSection,
  MainProductSectionContent,
  MainProductSectionImage,
  RowSection,
  RowSectionFlipped,
  SectionContent,
  SectionImage,
  SpaceSelectionButton,
  SpacesSection,
  Title,
  TitleSignature,
} from "./Product-v2.style";

const productsAndSpaces = [
  {
    title: "Laminates",
    content:
      "We are India's leading decorative laminate manufacturer and seller. It's a brand that the people has relied on for years to provide only the best laminates. We provide the best laminates in India, which are expertly produced utilizing a proprietary method in which specific resins add extra strength to our laminates, making them scratch and corrosion resistant.",
    spaces: [
      {
        title: "Living Room Space",
        images: [
          {
            image: require("../images/slider/living-room-render.jpg"),
            caption: "5833CB/PM - 5860 DO - 5867 SO",
          },
          {
            image: require("./gallery/laminates/living-room/D-L1.jpg"),
            caption: "5865 CGH - 1200 MF - 5530 SF",
          },
          {
            image: require("./gallery/laminates/living-room/D-L2.jpg"),
            caption: "5864 - 1209 CHG - 5834 CB - 5816 FW",
          },
        ],
      },
      {
        title: "Bed Room Space",
        images: [
          {
            image: require("../images/slider/bedroom-render.jpg"),
            caption: "5502 VM - 5561 SF - 1701 SF",
          },
          {
            image: require("./gallery/laminates/bed-room/B-L1.jpg"),
            caption: "1210 CGH - 5516 SF",
          },
          {
            image: require("./gallery/laminates/bed-room/B-L2.jpg"),
            caption: "5813 SF - 5533 SF - 5531 SF - 5528 RC",
          },
        ],
      },
      {
        title: "Kitchen Space",
        images: [
          {
            image: require("../images/slider/kitchen-render.jpg"),
            caption: "1209 CHG - 1210 CHG - 5857 VNR - 5833 PM",
          },
          {
            image: require("./gallery/laminates/kitchen/K-L1.jpg"),
            caption: "1200 CHG - 5837 CHG - 1207 SF",
          },
          {
            image: require("./gallery/laminates/kitchen/K-L2.jpg"),
            caption: "1207 SF - 5833 CHG",
          },
        ],
      },
      {
        title: "Office Space",
        images: [
          {
            image: require("../images/slider/office-render.jpg"),
            caption: "1209 CHG - 1210 CHG - 5857 VNR - 5833 PM",
          },
          {
            image: require("./gallery/laminates/office/O-L1.jpg"),
            caption: "1200 CHG - 5837 CHG - 1207 SF",
          },
          {
            image: require("./gallery/laminates/office/O-L2.jpg"),
            caption: "1207 SF - 5833 CHG",
          },
        ],
      },
    ],
  },
  {
    title: "Veneers",
    content:
      "With the changing designing techniques in the interiors, veneer wooden works have changed the entire game. We are providing the best veneer's  on which the customer's relies. We are using the best wood to cult the amazing textures turned into veneers which have high strength and amazing texture. We specialise in turning your spaces into Modern designs using our vintage culture and heritage",
    spaces: [
      {
        title: "Living Room Space",
        images: [
          {
            image: require("../images/living-room-render.jpeg"),
            caption: "5833CB/PM - 5860 DO - 5867 SO",
          },
          {
            image: require("./gallery/veneers/living-room/1.jpeg"),
            caption: "5838 OK - 5867 VNR",
          },
          {
            image: require("./gallery/veneers/living-room/2.jpeg"),
            caption: "Sunrise",
          },
        ],
      },
      {
        title: "Bed Room Space",
        images: [
          {
            image: require("./gallery/veneers/bed-room/1.jpeg"),
            caption: "5583 AHG",
          },
          {
            image: require("./gallery/veneers/bed-room/2.jpeg"),
            caption: "5558 SF",
          },
          {
            image: require("./gallery/veneers/bed-room/3.jpeg"),
            caption: "5546 SF",
          },
          {
            image: require("./gallery/veneers/bed-room/4.jpeg"),
            caption: "5544 SF",
          },
        ],
      },
      {
        title: "Kitchen Space",
        images: [
          {
            image: require("../images/slider/kitchen-render.jpg"),
            caption: "1209 CHG - 1210 CHG - 5857 VNR - 5833 PM",
          },
          {
            image: require("./gallery/veneers/kitchen/1.jpeg"),
            caption: "EBONY (HZ)",
          },
          {
            image: require("./gallery/veneers/kitchen/2.jpeg"),
            caption: "5837 PM",
          },
        ],
      },
      {
        title: "Office Space",
        images: [
          {
            image: require("../images/slider/office-render.jpg"),
            caption: "1209 CHG - 1210 CHG - 5857 VNR - 5833 PM",
          },
          {
            image: require("./gallery/veneers/office/O-V1.jpg"),
            caption: "EBONY (HZ)",
          },
          {
            image: require("./gallery/veneers/office/O-V2.jpg"),
            caption: "5837 PM",
          },
        ],
      },
    ],
  },
];

const Product = () => {
  const [selectedProduct, setSelectedProduct] = useState(0);
  // const [isOtherProduct, setIsOtherProduct] = useState(false);
  const ref = React.createRef();

  const onProductClick = (clickedItem) => {
    // window.scrollTo(0, ref.current.offsetTop);
    console.log(clickedItem);
    ref.current.scrollIntoView({ behavior: "smooth" });
    if (selectedProduct !== clickedItem) setSelectedProduct(clickedItem);
    else return;
  };

  return (
    <div>
      <HeroSection>
        <HeroContainerTitleSide>Laminates</HeroContainerTitleSide>
        <HeroContentSection>
          <TitleSignature>Revive Indoors</TitleSignature>
          <Title>Our Products</Title>
          <HeroParagraph>
            A collection of cutting-edge items, all expertly crafted from the
            best raw materials available. Open your arms to the most luxurious
            textures and gorgeous designs, made with laser precision
            particularly for you. Turning spaces to swag spaces.
          </HeroParagraph>
          <HeroButtons>
            <ButtonCustomProduct
              customStyle={{
                margin: "0px 0px 0px 0px",
                text: "Laminates",
              }}
              serial={0}
              onProductClick={onProductClick}
            />
            <ButtonCustomProduct
              customStyle={{
                margin: "0px 0px 0px 0px",
                text: "Veneers",
              }}
              serial={1}
              onProductClick={onProductClick}
            />
            <ButtonCustomProduct
              customStyle={{
                margin: "0px 0px 0px 0px",
                text: "Products",
              }}
              serial={2}
              onProductClick={onProductClick}
            />
            <ButtonCustomProduct
              customStyle={{
                margin: "0px 0px 0px 0px",
                text: "Others",
              }}
            />
          </HeroButtons>
        </HeroContentSection>
      </HeroSection>
      {selectedProduct === 2 ? (
        <OtherProductInfo reference={ref} />
      ) : (
        <ProductInfo
          reference={ref}
          product={productsAndSpaces[selectedProduct]}
        />
      )}
      <GoToTop />
    </div>
  );
};

const OtherProductInfo = (props) => {
  return (
    <>
      <RowSection ref={props.reference}>
        <SectionImage>
          <img
            src={require("./images/plywood.jpg")}
            alt="Swag Plywood"
            width={"100%"}
          />
        </SectionImage>
        <SectionContent>
          <TitleSignature>Plywood</TitleSignature>
          <Content>
            Transforming spaces with other unique and modern laminates which are
            beautiful and durable. We provide mix and match variety for
            breathtaking graphics patterns and finishes.
            <br />
            <br />
            <b>IS:710 Grade, Callibrated ,Total Hardwood</b> <br />
            <b>Size-8'x4',100% BWP</b><br />
            <b>Available in 18mm, 12mm,9mm & 6mm Thickness</b>

          </Content>
        </SectionContent>
      </RowSection>
      <RowSectionFlipped>
        <SectionImage>
          <img
            src={require("./images/block-board.jpg")}
            alt="Swag Block Board"
            width={"100%"}
          />
        </SectionImage>
        <SectionContent>
          <TitleSignature>Block Board</TitleSignature>
          <Content>
            Manufacturing lumber core man made board with the core made from
            strips of softwood making it light wait and can be extensively used
            for building light weight and strong interiors.
            <br />
            <br />
            <b>Double Core,Callibrated</b> <br />
            <b>Size-8'x4', 100 % New Zealand Pine Wood</b><br />
            <b>Available in 19mm Thickness</b>
            
            
          </Content>
        </SectionContent>
      </RowSectionFlipped>
      <RowSection>
        <SectionImage>
          <img
            src={
              "https://www.jeld-wen.co.uk/getmedia/c5dc6653-7b09-4202-b6b0-9527cf94db08/Veneer-walnut-fusion_05_2560.jpg"
            }
            alt="Swag Plywood"
            width={"100%"}
          />
        </SectionImage>
        <SectionContent>
          <TitleSignature>Flush Door</TitleSignature>
          <Content>
            Transforming spaces with other unique and modern laminates which are
            beautiful and durable. We provide mix and match variety for
            breathtaking graphics patterns and finishes.
            <br />
            <br />
            <b>Triple Core,Pine frame and Popular Filling</b><br />
            <b>Size (H) 82" & 94";(W)24" & 48",100 % BWP,</b><br />
            <b>Available in 30mm Thickness</b>
          </Content>
        </SectionContent>
      </RowSection>
    </>
  );
};

const ProductInfo = (props) => {
  const spaces = props.product.spaces;

  const [space, setSpace] = useState(0);

  const onSpaceClick = (clickedItem) => {
    if (space !== clickedItem) setSpace(clickedItem);
    else return;
  };

  return (
    <MainProductSection ref={props.reference}>
      <Title>{props.product.title}</Title>
      <MainParagraph>{props.product.content}</MainParagraph>
      <Title style={{ fontSize: "1.7rem", marginTop: "2%" }}>
        Design your space
      </Title>
      <MainParagraph>
        <SpaceItemButton
          text={"Living Room"}
          serial={0}
          onSpaceClick={onSpaceClick}
        />
        ,
        <SpaceItemButton
          text={"Bed Room"}
          serial={1}
          onSpaceClick={onSpaceClick}
        />{" "}
        ,
        <SpaceItemButton
          text={"Office"}
          serial={3}
          onSpaceClick={onSpaceClick}
        />{" "}
        and
        <SpaceItemButton
          text={"Kitchen"}
          serial={2}
          onSpaceClick={onSpaceClick}
        />
        .
      </MainParagraph>
      <Title style={{ fontSize: "1.7rem", marginTop: "3%" }}>
        {spaces[space].title}
      </Title>
      <ImageGrid images={spaces[space].images} />
    </MainProductSection>
  );
};

const ImageGrid = (props) => {
  const images = props.images;
  const [selectedImage, setSelectedImage] = useState(0);

  const onGalleryImageClick = (clickedItem) => {
    if (selectedImage !== clickedItem) setSelectedImage(clickedItem);
    else return;
  };

  // useEffect(() => {
  //   if (selectedImage !== 0) setSelectedImage(0);
  // },[selectedImage]);

  return (
    <MainContentSubSection>
      <MainProductSectionImage>
        <img
          src={images[selectedImage].image}
          alt="Swag Decorative"
          width={"100%"}
        />
        <ImageCaption>{images[selectedImage].caption}</ImageCaption>
      </MainProductSectionImage>
      <MainContentSubSectionPara>
        <MainGallerySection>
          {images.map((image, index) => {
            if (selectedImage === index) {
              return (
                <GalleryImage
                  selected
                  key={index}
                  serial={index}
                  content={{
                    source: image.image,
                  }}
                  onGalleryImageClick={onGalleryImageClick}
                />
              );
            } else {
              return (
                <GalleryImage
                  key={index}
                  serial={index}
                  content={{
                    source: image.image,
                  }}
                  onGalleryImageClick={onGalleryImageClick}
                />
              );
            }
          })}
        </MainGallerySection>
      </MainContentSubSectionPara>
    </MainContentSubSection>
  );
};

const GalleryImage = (props) => {
  return (
    <MainGallerySingleImageContainer
      onClick={() => {
        props.onGalleryImageClick(props.serial);
      }}
      selected={props.selected}
    >
      <MainGallerySingleImage
        src={props.content.source}
        alt="Swag Decorative"
      />
    </MainGallerySingleImageContainer>
  );
};

export const SpaceItemButton = (props) => {
  return (
    <SpaceSelectionButton
      onClick={() => {
        props.onSpaceClick(props.serial);
      }}
    >
      {props.text}
    </SpaceSelectionButton>
  );
};

export default Product;
