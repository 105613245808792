import styled from "styled-components";

export const ProductSection = styled.div`
  background: white;
  padding: 7% calc((100vw - 1100px) / 2);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const AboutSectionTitle = styled.p`
  font-family: "a Auto Signature", sans-serif;
  font-size: 1.8rem;
  color: #b72e2c;
  margin: 0;
  font-weight: 800;
`;

export const SectionTitle = styled.p`
  font-family: "Poppins", sans-serif;
  color: #b72e2c;
  font-size: 1.2rem;
  font-weight: 800;
  margin: 0;
`;

export const SectionSubTitle = styled.p`
  font-family: "Poppins", sans-serif;
  color: black;
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 1%;
`;

export const ProductSectionImages = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  flex-wrap: nowrap;
`;

export const HomeImageBottomContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0 20px 0 20px;
  justify-content: center;
`;

export const HomeImageBottomContainerImage = styled.img`
  position: relative;
  border-radius: 20px;
  box-shadow: 9px 10px 21px -4px rgba(4, 24, 96, 0.23);
  z-index: 10;
`;

export const HomeImageBottomContainerImageCurve = styled.div`
  position: absolute;
  background-color: #1f1f1f;
  border-radius: 50%;
  width: 150px;
  bottom: -50px;
  height: 150px;
  z-index: 1;
`;

export const AboutSectionHome = styled.div`
  padding: 5% calc((100vw - 1100px) / 2);
  /* background: white; */
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 10% 5%;
  }
`;

export const AboutSectionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 3%;

  @media screen and (max-width: 768px) {
    margin: 20% 0 0 0;
  }
`;

export const AboutSectionParagraph = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: black;
  line-height: 24px;
`;

export const AboutSectionImage = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  margin-right: 3%;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin: 0% 10%;
  }
`;

export const AboutSectionImageImg = styled.img`
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const AboutSectionImageBorder = styled.div`
  position: absolute;
  border-color: #d7d7d7;
  border-style: solid;
  border-width: 10px;
  height: 80%;
  width: 100%;
  bottom: -20px;
  right: 20px;
  z-index: 2;
`;
