import "./App.css";
import "./home/home.css";
import Navbar from "./components/header/Navbar";
import Sections from "./components/Section";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from "./contact/Contact";
import Footer from "./components/footer/Footer";
import {BottomSectionStyle, Title } from "./components/SectionStyle";
import ImageWithCaption from "./components/ImageWithCaption";
import {
  AboutSectionContent,
  AboutSectionHome,
  AboutSectionImage,
  AboutSectionImageBorder,
  AboutSectionImageImg,
  AboutSectionParagraph,
  AboutSectionTitle,
} from "./home/home.style";
import {ButtonCustom} from "./components/Button";
import Slider from "./components/slider/Slider";
import Catalogue from "./catalogues/Catalogue";
import { AnimatePresence, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { useEffect } from "react";
import { Marquee } from "./components/Marquee";
import Carousel from "./home/Carousel";
import Product from "./product/Product-v2";
import About from "./about/About-v2";
import GoToTop from "./ScrollToTop";

function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <AnimatePresence>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/catalogues" element={<Catalogue />} />
            <Route path="/products" element={<Product />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/sign-up" />
          </Routes>
        </AnimatePresence>
        <Footer />
      </Router>
    </div>
  );
}

const Home = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Slider />
      <Sections />
      <AboutSectionOnHome />
      <Carousel />
      {/* <ProductMultiple/> */}
      <Marquee>Never letting elegance go out of style!</Marquee>
      <BottomSection />
      <GoToTop/>
    </motion.div>
  );
};

// const HomeImageWithBottom = (content) => {
//   return (
//     <HomeImageBottomContainer>
//       <HomeImageBottomContainerImage
//         src={content.content.url}
//         alt="Swag Decorative"
//         width={"100%"}
//       />
//       <HomeImageBottomContainerImageCurve />
//     </HomeImageBottomContainer>
//   );
// };

const AboutSectionOnHome = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const contentAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 1.9,
          bounce: 0.2,
        },
      });
      contentAnimation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 1.9,
          bounce: 0.2,
        },
      });
    } else {
      animation.start({
        x: "-20vw",
      });
      contentAnimation.start({
        x: "20vw",
      });
    }
  }, [inView, animation, contentAnimation]);

  return (
    <AboutSectionHome ref={ref}>
      <AboutSectionImage as={motion.div} animate={animation}>
        <AboutSectionImageImg
          src={require("./images/home-about-us-section.webp")}
          alt="Swag Decorative"
        />
        <AboutSectionImageBorder />
      </AboutSectionImage>
      <AboutSectionContent as={motion.div} animate={contentAnimation}>
        <AboutSectionTitle>About us</AboutSectionTitle>
        <Title>
          Adding the Swag Factor to <br />
          Your Spaces Crafting Excellence
        </Title>
        <AboutSectionParagraph>
          The Tek Chand Group's Foundation laid by Shri. Prem Chand Goel
          Director of the company in the year 1983. Over the years, through hard
          work and the support of our loyal customers, we have grown into
          trading an extensive catalogue of Plywoods, Blockboards, Laminates,
          and Shuttering Ply.
          <br />
          <br />
          Brilliant knowledge of market and good relations with his 400+
          clientele network in Delhi NCR itself.
        </AboutSectionParagraph>
        <ButtonCustom
          customStyle={{
            margin: "15px 0px 0px 0px",
            text: "Read more",
            linkTo: "/about",
          }}
        />
      </AboutSectionContent>
    </AboutSectionHome>
  );
};

const BottomSection = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const contentAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 1.9,
          bounce: 0.2,
        },
      });
      contentAnimation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 1.9,
          bounce: 0.2,
        },
      });
    } else {
      animation.start({
        x: "-20vw",
      });
      contentAnimation.start({
        x: "20vw",
      });
    }
  }, [inView, animation, contentAnimation]);

  return (
    <BottomSectionStyle ref={ref} className="image-section">
        <motion.div animate={animation} className="image-sub-section">
          <ImageWithCaption
            content={{
              title: "Design that transform spaces",
              url: require("./images/home-bottom-office.jpg"),
            }}
          />
          <ImageWithCaption
            content={{
              title: "Culture creates value",
              url: require("./images/home-bottom-kitchen.jpeg"),
            }}
          />
        </motion.div>
        <motion.div animate={contentAnimation} className="second_image">
          <div className="text">Designs Creates Culture!</div>
        </motion.div>
    </BottomSectionStyle>
  );
};

export default App;
