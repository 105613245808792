import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// const ButtonStyled = styled.a`
//   background: #232426;
//   cursor: pointer;
//   position: relative;
//   padding: 16px 25px;
//   font-size: 14px;
//   text-transform: uppercase;
//   text-decoration: none;
//   border-radius: 24px;
//   font-weight: 700;
//   font-family: "Poppins", sans-serif;

//   &::before,
//   &::after {
//     position: absolute;
//     top: 0;
//     display: block;
//     height: 100%;
//     width: 0;
//     border-radius: 24px;
//     background-color: #e4562a;
//     content: "";
//   }

//   &::before {
//     left: 0;
//   }

//   &::after {
//     right: 0;
//     transition: width 0.3s;
//   }

//   &:hover::before {
//     width: 100%;
//     transition: width 0.3s;
//   }

//   &:hover::after {
//     background-color: transparent;
//     width: 100%;
//   }
// `;

const ButtonText = styled.span`
  font-size: 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif !important;
  color: #fff;
  z-index: 10;
`;

const ButtonStyled2 = styled.button`
  width: fit-content;
  height: 45px;
  color: #232426;
  border-radius: 24px;
  padding: 5px 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  margin: ${(props) => props.margin};
  /* box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1); */
  outline: none;
  background: #232426;
  border: none;
  z-index: 1;
  text-decoration: none;

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: #ca2e2c;
    border-radius: 24px;
    /* box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
      7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1); */
    transition: all 0.3s ease;
  }
  &:hover {
    color: #fff;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }
`;

const ButtonStyledProduct = styled.button`
  width: fit-content;
  height: 55px;
  color: #232426;
  border-radius: 50px;
  padding: 5px 70px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  margin: ${(props) => props.margin};
  /* box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1); */
  outline: none;
  background: black;
  border-width: 1px;
  border-color: white;
  z-index: 1;
  text-decoration: none;

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: #ca2e2c;
    border-radius: 24px;
    /* box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
      7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1); */
    transition: all 0.3s ease;
  }
  &:hover {
    color: #fff;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 5% 0 0 0;
  }
`;

const ButtonTextProduct = styled.span`
  font-size: 1.1rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif !important;
  color: #fff;
  z-index: 10;
`;

ButtonStyled2.defaultProps = {
  margin: "0px 0px 0px 0px",
};

export const ButtonCustom = (customStyle) => {
  return (
    <Link to={`${customStyle.customStyle.linkTo}`}>
      <ButtonStyled2 margin={customStyle.customStyle.margin}>
        <ButtonText>{customStyle.customStyle.text}</ButtonText>
      </ButtonStyled2>
    </Link>
  );
};

export const ButtonCustomProduct = (props) => {
  return (
    <ButtonStyledProduct
      onClick={() => {
        props.onProductClick(props.serial);
      }}
      margin={props.customStyle.margin}
    >
      <ButtonTextProduct>{props.customStyle.text}</ButtonTextProduct>
    </ButtonStyledProduct>
  );
};

export const FormButtonCustom = (props) => {
  return (
    <ButtonStyled2 type={props.type}>
      <ButtonText>{props.customStyle.text}</ButtonText>
    </ButtonStyled2>
  );
};
