import React from "react";
import styled from "styled-components";

const CardBlack = styled.div`
  color: white;
  text-align: center;
  border-radius: 24px;
  padding: 3% 2%;
  flex: 1;

  @media screen and (max-width: 768px) {
    margin-top: 3%;
  }
`;

const Title = styled.p`
  font-family: "Belleza", sans-serif;
  color: #b72e2c;
  font-size: 1.5rem;
  margin: 14px 0 0 0;
  text-transform: uppercase;
  font-weight: 600;
`;

const Content = styled.p`
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  line-height: 32px;
  margin-top: 0.8%;
  margin-bottom: 0;
`;

const ValuesCard = (content) => {
  return (
    <CardBlack>
      <img src={content.content.image} alt="Home" width={"50px"} />
      <Title>{content.content.title}</Title>
      <Content>{content.content.content}</Content>
    </CardBlack>
  );
};

export default ValuesCard;
