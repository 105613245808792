import React from "react";
import GoToTop from "../ScrollToTop";
import {
  Content,
  ImageGrid,
  Item,
  ItemContent,
  ItemImage,
  ItemTitle,
  Section,
  SectionImage,
  SectionImageGrid,
  SubTitle,
  TitleSignature,
} from "./Catalogue.style";

const catalogues = [
  {
    title: "Swag 1mm",
    sub_title: "Playful Style",
    right_image: require("./images/1mm-cover-v2.jpeg"),
    left_image_1: require("./images/1mm-1.png"),
    left_image_2: require("./images/1mm-2.png"),
    download_link:
      "https://drive.google.com/file/d/1X_N36SsoAGfh0vtI8M5WZlMYegrD50q9/view?usp=drive_link",
  },
  {
    title: "Swag 0.8mm",
    sub_title: "Playful Style",
    right_image: require("./images/8mm-cover-v2.jpeg"),
    left_image_1: require("./images/0.8mm-1.png"),
    left_image_2: require("./images/0.8mm-2.png"),
    download_link:
      "https://drive.google.com/file/d/17Ueme5RnRjq1VQKg9DTTI9xgkCq-i70i/view?usp=drive_link",
  },
  {
    title: "Swag 0.72mm",
    sub_title: "Playful Style",
    right_image: require("./images/0.72mm-cover_v2.png"),
    left_image_1: require("./images/0.72mm-1.png"),
    left_image_2: require("./images/0.8mm-2.png"),
    download_link:
      "https://drive.google.com/file/d/1e55O8O_nMHZOsZTLzr1s8RY5xbGmVJjH/view?usp=drive_link",
  },
  {
    title: "Swag Door Skin",
    sub_title: "Playful Style",
    right_image: require("./images/door-cover_v2.png"),
    left_image_1: require("./images/door-1.png"),
    left_image_2: require("./images/door-2.png"),
    download_link:
      "https://drive.google.com/file/d/17VdmT08BhYtDz_N1QvTHsEbv7boMU3Dd/view?usp=drive_link",
  },
  {
    title: "Decorative Veneer",
    sub_title: "Playful Style",
    right_image: require("./images/veneer-cover.png"),
    left_image_1: require("./images/veneer-1.png"),
    left_image_2: require("./images/veneer-2.png"),
    download_link:
      "https://drive.google.com/file/d/1gFLFw9f33lva8XoXWNExGPMzDxTMzs_9/view",
  },
  {
    title: "Club Ply",
    sub_title: "Playful Style",
    right_image: require("./images/club.png"),
    left_image_1: require("./images/club-ply-1.png"),
    left_image_2: require("./images/club-ply-2.png"),
    download_link:
      "https://drive.google.com/file/d/10W3Na6Amk6Sc_gCRBFGSPstVKM3vGi5U/view",
  },
];

const Catalogue = () => {
  return (
    <>
      <Section>
        <TitleSignature>Our Catalogue</TitleSignature>
        <Content>
          Dive into the widest range of Swag Decoratives, and explore our
          catalogues to learn more about our products , designs and deliveries.
          From designing your rooms to bathrooms we have your cover. We design
          and decorate in Swag.
        </Content>
        {catalogues.map((catalogue, index) => {
          return <CatalogueItem catalogue={catalogue} key={index} />;
        })}
      </Section>
      <GoToTop />
    </>
  );
};

const CatalogueItem = (props) => {
  return (
    <Item>
      <ItemContent>
        <div>
          <ItemTitle>{props.catalogue.sub_title}</ItemTitle>
          <SubTitle>{props.catalogue.title}</SubTitle>
        </div>
        <ImageGrid>
          <SectionImageGrid>
            <ItemImage
              src={props.catalogue.left_image_1}
              alt={`Swag Decorative - ${props.catalogue.title} Catalogue`}
            />
          </SectionImageGrid>
          <SectionImageGrid>
            <ItemImage
              src={props.catalogue.left_image_2}
              alt={`Swag Decorative - ${props.catalogue.title} Catalogue`}
            />
          </SectionImageGrid>
        </ImageGrid>
      </ItemContent>
      <SectionImage>
        <a
          href={props.catalogue.download_link}
          target="_blank"
          rel="noreferrer"
        >
          <ItemImage
            src={props.catalogue.right_image}
            alt={`Swag Decorative - ${props.catalogue.title} Catalogue`}
          />
        </a>
      </SectionImage>
    </Item>
  );
};

export default Catalogue;
