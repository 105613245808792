import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

const Container = styled.div`
  background-color: #f5f5f5;
  height: 100%;
  padding: 0rem calc((100vw - 1100px) / 2);
  z-index: 10;
  color: white;
  /* -webkit-box-shadow: 0px 0px 10px 1px rgba(41, 41, 41, 0.1);
  -moz-box-shadow: 0px 0px 10px 1px rgba(41, 41, 41, 0.1);
  box-shadow: 0px 0px 10px 1px rgb(41 41 41 / 10%); */

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 5% 5% 0 5%;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 3%;
  /* Third Nav */
  /* justify-content: flex-start; */
`;

const Copyright = styled.p`
  text-align: center;
  font-size: 0.7rem;
  color: black;
  margin: 0;
  padding-top: 2%;
  padding-bottom: 1.2%;
  @media screen and (max-width: 768px) {
    margin-top: 10%;
    margin-bottom: 2%;
  }
`;

const Footer = () => {
  return (
    <Container>
      <FooterContainer>
        <img src={require("../../swag-logo.png")} alt="logo" height={"49px"} />
        <div style={{ paddingTop: "15px" }}>
          <a
            href="https://www.facebook.com/swagdecorative/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              style={{ marginRight: "20px", color: "black" }}
              icon={brands("facebook")}
              size="2x"
            />
          </a>
          <a
            href="https://instagram.com/swagdecorative"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={brands("instagram")}
              size="2x"
            />
          </a>
        </div>
      </FooterContainer>
      <Copyright>Copyright © 2022. Swag. All rights reserved.</Copyright>
    </Container>
  );
};

export default Footer;
