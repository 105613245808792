import styled from "@emotion/styled";

const MarqueeStyle = styled.div`
  width: 100%;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;

  p {
    margin: 0;
    display: inline-block;
    padding-left: 100%;
    font-size: 6rem;
    font-family: "Belleza", sans-serif;
    font-weight: 900;
    animation: marquee 15s linear infinite;
  }

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
`;

export const Marquee = (props) => {
  return (
    <MarqueeStyle>
      <p>{props.children}</p>
    </MarqueeStyle>
  );
};
