import {
  Content,
  HeroSection,
  OurValues,
  RowSection,
  RowSectionFlipped,
  Section,
  SectionContent,
  SectionImage,
  SubTitle,
  TitleSignature,
} from "./About-v2.style";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { TextCenter } from "../catalogues/Catalogue.style";
import ValuesCard from "../components/ValuesCard";
import GoToTop from "../ScrollToTop";

const About = () => {
  return (
    <>
      <Section>
        <TitleSignature>About us</TitleSignature>
        <SubTitle>
          Best Designs and
          <br />
          Ideas for your Spaces
        </SubTitle>
        <Content>
          The Tek Chand Group's Foundation laid by Shri. Prem Chand Goel
          Director of the company in the year 1983. Over the years, through hard
          work and the support of our loyal customers, we have grown into
          trading an extensive catalogue of Plywoods, Blockboards, Laminates,
          and Shuttering Ply. <br />
          <br />
          Brilliant knowledge of market and good relations with his 400+
          clientele network in Delhi NCR itself. Under guidance of Shri Prem
          Chand Goel Swag was established in 2017 with the thought of
          revolutionizing the vision of home decore with huge variety of premium
          laminate and plywood managed by Mr. Nageen Gupta, Mr. Girivar Goel,
          Mr. Mohit Goyal & Mr. Sparsh Goyal as a co-founder.
        </Content>
      </Section>
      <HeroSection
        style={{ backgroundImage: `url(${require("./about-us-hero.webp")})` }}
      ></HeroSection>
      <Section>
        <LeftSideImageWithContent />
        <RightSideImageWithContent />
        <>
          <TextCenter>
            <TitleSignature>Our Values</TitleSignature>
          </TextCenter>
          <OurValues>
            <ValuesCard
              content={{
                image: require("./icon/trust.png"),
                title: "Trust",
                content:
                  "Providing customers the product they can trust for 100's of years.",
              }}
            />
            <ValuesCard
              content={{
                image: require("./icon/quality.png"),
                title: "Quality",
                content:
                  "Delivering quality making interiors strong and luminant.",
              }}
            />
            <ValuesCard
              content={{
                image: require("./icon/innovation.png"),
                title: "Innovative",
                content: "With time we believe in advancement and innovation.",
              }}
            />
          </OurValues>
          <OurValues>
            <ValuesCard
              content={{
                image: require("./icon/rocket.png"),
                title: "Speed",
                content:
                  "We are quick to decide, respond and execute, believing on-time deliveries.",
              }}
            />
            <ValuesCard
              content={{
                image: require("./icon/call-center.png"),
                title: "After Sales Service",
                content:
                  "We value customers and their needs post the delivery.",
              }}
            />
            <ValuesCard
              content={{
                image: require("./icon/satisfaction.png"),
                title: "Customer Satisfaction",
                content:
                  "We achieve success by customer's feedback and collaboration.",
              }}
            />
          </OurValues>
        </>
      </Section>
      <GoToTop />
    </>
  );
};

const LeftSideImageWithContent = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const contentAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 1.9,
          bounce: 0.2,
        },
      });
      contentAnimation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 1.9,
          bounce: 0.2,
        },
      });
    } else {
      animation.start({
        x: "-20vw",
      });
      contentAnimation.start({
        x: "20vw",
      });
    }
  }, [inView, animation, contentAnimation]);

  return (
    <RowSection ref={ref}>
      <SectionImage as={motion.div} animate={animation}>
        <img
          src={require("../images/home-full-fledged-section.webp")}
          alt="Home"
          width={"100%"}
        />
      </SectionImage>
      <SectionContent as={motion.div} animate={contentAnimation}>
        <TitleSignature>Motivation</TitleSignature>
        <Content>
          To serve the customer with trust and respect. We believe in delivering
          supreme quality and satisfy all the needs of our customers
        </Content>
      </SectionContent>
    </RowSection>
  );
};

const RightSideImageWithContent = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const contentAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 1.9,
          bounce: 0.2,
        },
      });
      contentAnimation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 1.9,
          bounce: 0.2,
        },
      });
    } else {
      animation.start({
        x: "-20vw",
      });
      contentAnimation.start({
        x: "20vw",
      });
    }
  }, [inView, animation, contentAnimation]);

  return (
    <RowSectionFlipped ref={ref}>
      <SectionContent as={motion.div} animate={animation}>
        <TitleSignature>Vision</TitleSignature>
        <Content>
          We believe in expansion, without detroiting the quality and standards
          of our product. PAN India expansion with the finest wooden boards.
        </Content>
      </SectionContent>
      <SectionImage as={motion.div} animate={contentAnimation}>
        <img
          src={require("../images/home-bottom-office.jpg")}
          alt="Home"
          width={"100%"}
        />
      </SectionImage>
    </RowSectionFlipped>
  );
};

export default About;
